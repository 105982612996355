import TableShoes from './components/TableShoes/TableShoes';
import style from './App.module.scss'


function App() {
  return (
    <div className={style.app}>
      <TableShoes/>
    </div>
  );
}

export default App;
